
import {useRoutes} from 'react-router-dom';
import { routes } from './routes'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';


function App() {
  const routing = useRoutes(routes)
  
  const theme = createTheme({
    palette: {
      primary: {
        main: '#e6a800'
      },
      secondary: {
        main: '#669900'
      },
      pink: {
        main: '#ff83ac'
      },
      black: {
        main: "#000"
      },
      blue: {
        main: '#329bb3'
      }
    }
  });

  return (
    
    <MuiThemeProvider theme={theme}>
      {routing}
    </MuiThemeProvider>
  )
}

export default App;
