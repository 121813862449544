import React, {useEffect } from 'react'
import shopping from '../../images/shopping.png'
import { AppName } from '../../utils/constants'

export default function About(props){
    
    useEffect(()=>{

        
        const prevTitle = document.title
        document.title = "About us | What is the mecca store | Get to know us"
        return () => {
            document.title = prevTitle //when unmounting return back the original title header
        }
    }, [])
    return (
        <>

           
            <div className="container mt-4 pt-2 mb-4 pb-5">
                <div className="row py-2 my-2">

                    <div className="col" style={{marginBottom: '30px'}}>
                    <h2 className="st-section-heading-title text-center">Who We Are</h2>
                    {/* <div className="st-section-heading-subtitle text-center" >
                    
                    </div> */}
                    </div> 

                </div>

                <div className="row">

                    <div className="col-md-6">
                        <h2 className="st-text-block-title" style={{paddingBottom: "20px"}}>
                            {AppName} Shop: <span style={{color: '#fcb800' }}>Your One-Stop Shop for Kitchenware Products</span>
                        </h2>
                        <div className="p-2 p-md-0" style={{lineHeight: '40px',fontSize: '17px',}}>
                            <p>We are your one-stop shop for all your kitchenware needs! We carry a wide variety of kitchenware products, from pots and pans to utensils and gadgets. We have everything you need to equip your kitchen with the latest and greatest kitchenware!</p>
                            <p>We are dedicated to providing our customers with the highest quality kitchenware products at the best possible prices. We offer a 100% satisfaction guarantee on all of our products. We are always here to help! If you have any questions, please feel free to contact us. Thank you for choosing our shop!</p>
                            
                        </div>  
                    </div>

                    <div className="col-md-6" >
                        
                        <img src={shopping} alt="shopping" className='d-none d-md-block mt-0 mt-md-5 pt-0 pt-md-3' style={{width: '100%', height: '500px'}} />
                    
                    
                    </div>

                    
                        {/* <div className="col-md-12 pt-5 mt-3" >
                            
                            
                            <div className="row" >

                                <div className="col-12">
                                    <div className="row my-2">

                                        <div className="col" style={{marginBottom: '50px'}}>
                                            <h2 className="st-section-heading-title text-center">Our specialists</h2>
                                        </div> 

                                    </div>
                                </div>

                                {
                                    gallery.map((item)=>
                                    
                                        <div className="col-md-3" >
                                            <img src={item.url} key={item.name} alt="Doctor" style={{width: '250px', borderRadius: '50%', height: '250px'}} />
                                            <p className="text-center mt-3 mb-0 pb-0" style={{fontWeight: 'bold', fontSize: '17px', fontFamily: 'times new roman'}}>
                                                {item.name}
                                                
                                            <br/><small>
                                                {
                                                    item.role
                                                }
                                            </small>
                                            </p>
                                        </div>
                                    
                                    )
                                }
                            </div>
                            
                        
                        
                        </div> */}
                    
                </div>
            </div>
        
        </>
    )

}
