
import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    
    prodBannerImg: {
        width: 'auto', 
        height: '400px',
      [theme.breakpoints.down('md')]: {
        height: '300px',
      },
    },
    moreImgs: {
        display: 'flex',
        width: '97%',
        overflowX: 'auto',
        marginLeft: '10px',
        marginRight: '10px',
        marginTop: '15px'
    },
    otherImgs: {
        cursor: 'pointer',
        border: '1px solid #d3d3d3',
        marginRight: '5px',
        '&:hover': {
            transform: 'scale(0.95)'
        },
    },
    orderBtn: {
        width: '60%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
}))