import { Button, Card, Grid } from '@material-ui/core'
import React, {useEffect, useState } from 'react'
import shopping from '../../images/bg11.jpg'
import '../../utils/css/contact.css'
import { useStyles } from './style'
import Snackbar from '@material-ui/core/Snackbar';
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'

export default function Contact(props){
    
    const classes =  useStyles()
    const [message, setMessage] = useState({})
    const [openToast, setOpenToast] = useState({
        open: false,
        severity: 'success',
        msg: "message"
    })

    const sendMsg = () => {
        if(!message.email) {
            handleOpenToast("Email field is required", "error")
        } else if(!message.phone) {
            handleOpenToast("Phone number field is required", "error")
        } else if(!message.phone) {
            handleOpenToast("Phone number field is required", "error")
        } else if(!message.name) {
            handleOpenToast("Your name is required", "error")
        } else {
            const headers = {
                "Content-Type": "application/json",
            }
            // const formData = new FormData()
            // formData.append('totalPrice', totalPrice);
        
            //axios.post(stripePaymentIntentRestUrl , formData, {headers:headers})
            handleOpenToast("Sending email. Please wait.", "success")
           const url = `https://meccastore.co.ke/mail.php?email=${message.email}&name=${message.name}&msg=${message.msg}&phone=${message.phone}`
            axios.get(url, {headers:headers})
                .then((data)=>{
    
                    console.log(data)
                    handleOpenToast("Mail sent successfully.", "success")
                })
                .catch((err)=>{
                    handleOpenToast("Could not send email. Try again later", "error")
                    console.log("error", err)
                })

        }
    }

    const handleChange = (key, val) => {
        setMessage({
            ...message,
            [key]: val
        })
    }

    const handleOpenToast = (msg="", severity = 'success')=> {
        setOpenToast(
            {
                ...openToast,
                open: true,
                severity: severity,
                msg: msg
            }
        )
    }
    const closeToast = (severity = 'success')=> {
        setOpenToast(
            {
                ...openToast,
                open: false,
                severity: severity
            }
        )
    }

    useEffect(()=>{

        
        const prevTitle = document.title
        document.title = "Contact us | Meet with our team | Drop at our offices | Send us a message"
        return () => {
            document.title = prevTitle //when unmounting return back the original title header
        }
    }, [])

    return (
        <>

            <div >
                <Grid container>
                    
                    <Grid item xs={12} md={4} className="position-relative">
                        <img src={shopping} className="img-fluid d-none d-md-block" style={{width: '100%', height: '800px'}} alt="..." />
                        <Card
                        
                            className={`${classes.contactForm} container px-4 py-3`}
                        >
                            <div >
                                <h1 className='my-3'>Come on in  <span style={{color: '#fcb800' }}>and take a look around!</span> </h1>
                                <p className='mb-4' style={{width: '100%', fontSize: '17px', lineHeight: '35px', wordBreak: 'break-word'}}>
                                    If you're looking for a shop that sells kitchenware products, you've come to the right place! We carry a wide variety of products to meet your needs, and our friendly staff is always here to help.
                                </p>
                                <strong className='my-3'> <span style={{color: '#000' }}>Drop a message:</span> </strong>
                                <div className='mt-2 mt-md-3'>
                                    <input 
                                        type="text" 
                                        className="form-control py-4  pl-3 rounded-pill" 
                                        style={{  fontSize: '18px', borderRadius: '5px', outline: 'none '}}  
                                        name="name" 
                                        placeholder="Your name*" 
                                        value={message.name || ""}
                                        onChange={(e)=>handleChange('name', e.target.value)}
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-sm-6 mt-2 mt-md-3">
                                        <input 
                                            type="text" 
                                            style={{  fontSize: '18px', }} 
                                            className="form-control py-4 pl-3 rounded-pill" 
                                            name="phone" 
                                            placeholder="Your Phone"
                                            value={message.phone || ""}
                                            onChange={(e)=>handleChange('phone', e.target.value)}
                                        />
                                    </div>
                                    <div className="col-sm-6 mt-sm-0 mt-2 mt-md-3">
                                        <input 
                                            type="text" 
                                            style={{ fontSize: '18px', borderRadius: '5px'}} 
                                            className="form-control py-4 pl-3 rounded-pill" 
                                            name="email" 
                                            placeholder="Email*" 
                                            value={message.email || ""}
                                            onChange={(e)=>handleChange('email', e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div style={{marginBottom: '20px'}}>
                                    <textarea 
                                        className="form-control mt-2 mt-md-3 rounded" 
                                        rows="5" 
                                        style={{ fontSize: '18px',  height: '130px'}} 
                                        name="msg" 
                                        placeholder="Message"
                                        value={message.msg || ""}
                                        onChange={(e)=>handleChange('msg', e.target.value)}
                                    ></textarea>
                                </div>


                                <Button onClick={sendMsg} color="primary" variant="contained" size="large" className="rounded-pill"  style={{color: "#fff", textTransform: 'none'}} >
                                    Send us a message
                                </Button>
                            </div>

                        </Card>
                    </Grid>

                    <Grid item xs={12} md={8}>
                        <iframe 
                            title="Revion plaza"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.819377238345!2d36.818878014101244!3d-1.2821549359817128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10d4423c2d67%3A0x776b277969e502a2!2sRevlon%20Plaza%2C%20Nairobi!5e0!3m2!1sen!2ske!4v1674458293783!5m2!1sen!2ske"
                            className={`${classes.contactMap}`}
                            allowFullScreen="" 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                    </Grid>
                </Grid>
                
            </div>

            
            <Snackbar open={openToast.open} autoHideDuration={6000} onClose={closeToast}>
                <Alert onClose={closeToast} severity={openToast.severity}>
                    {openToast.msg}
                </Alert>
            </Snackbar>
           
        
        </>
    )

}
