import { Avatar, Box, Breadcrumbs, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, Typography } from '@material-ui/core'
import React, {useEffect, useState } from 'react'
import ServicesCard from '../../components/Services/ServicesCard'
import { ShoppingBasket } from '@material-ui/icons'
import allProducts from '../../Data/shop_data.xlsx'
import * as xlsx from 'xlsx';
import { Pagination } from '@material-ui/lab'
import { useNavigate, useParams } from 'react-router'
import { useStyles } from './style'
import { capitalizeFirstLetter, categories } from '../../utils/constants'
import { useSelector } from 'react-redux'

export default function Shop(props){

    
    

    const productReducer = useSelector(state => state.productReducer)
    const [services, setServices] = useState([])
    let { category } = useParams()
    const [sortedService, setSortedService] = useState(services)
    const [selectedCategory, setSelectedCategory] = React.useState(categories.filter(item=>item.catUrl === category)[0].cat);
    const [selectedPrice, setSelectedPrice] = React.useState('')
    const [selectedFilter, setSelectedFilter] = React.useState('')
    const [page, setPage] = React.useState(1);
    const limit = 16
    const classes = useStyles()
    const navigate = useNavigate()


    const priceFilters = [
        {
            name: "Under 2000",
            min: 0,
            max: 2000,
        },
        {
            name: "2000 - 5000",
            min: 2000,
            max: 5000,
        },
        {
            name: "5000 - 10000",
            min: 5000,
            max: 15000,
        },
        {
            name: "10000 - 20000",
            min: 10000,
            max: 20000,
        },
        {
            name: "20000 & Above",
            min: 20000,
            max: 100000,
        },
    ]

    const filters = [
        {
            name: 'Alphabetically (A - Z)',
            id: 'alph_desc'
        },
        {
            name: 'Alphabetically (Z - A)',
            id: 'alph_asc'
        },
        {
            name: 'Prices (Low - High)',
            id: 'price_asc'
        },
        {
            name: 'Prices (High - Low)',
            id: 'price_desc'
        },
    ]

    const handleSearch = (e) => {
        const value = e.target.value
        setSortedService(
            services.filter(service=>service.title.toLowerCase().indexOf(value.toLowerCase()) > -1)
        )
    } 

    const handleCategoryChange = (e) => {
        const catName = categories.filter(item=>item.cat === e.target.value)[0].catUrl
        setSelectedCategory( e.target.value)
        navigate('/shop/'+catName)
    }
    
    const handlePriceChange = (e) => {
        setSelectedPrice(e.target.value)
    }

    const handleFilterChange = (e) => {
        setSelectedFilter(e.target.value)
    }
    
    const handlePageChange = (event, value) => {
      setPage(value);
    };
  
    useEffect(() => {

        if(productReducer.data) {
            let json = productReducer.data
            //check if we are getting data for  category
            if(category){
                json = json.filter(service=>service.category.toLowerCase().replace("/", "") === category.toLowerCase())
            }

            let listLimit = page*limit
            listLimit = json.length >= listLimit?listLimit:json.length
            setSortedService(json.slice(listLimit-limit, listLimit))
            setServices(json)
        }
        // if (allProducts) {
        //     var request = new XMLHttpRequest();
        //     request.open('GET', allProducts, true);
        //     // request.responseType = 'blob';
        //     request.responseType = "arraybuffer";
        //     request.onload = function() {


                
        //         /* convert data to binary string */
        //         var data = new Uint8Array(request.response);
        //         var arr = new Array();
        //         for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //         var excelFile = arr.join("");

        //         const workbook = xlsx.read(excelFile, { type: "binary" })
        //         const sheetName = workbook.SheetNames[0]
        //         const worksheet = workbook.Sheets[sheetName]
        //         let json = xlsx.utils.sheet_to_json(worksheet)

        //         //check if we are getting data for  category
        //         if(category){
        //             json = json.filter(service=>service.category.toLowerCase().replace("/", "") === category.toLowerCase())
        //         }

        //         let listLimit = page*limit
        //         listLimit = json.length >= listLimit?listLimit:json.length
        //         setSortedService(json.slice(listLimit-limit, listLimit))
        //         setServices(json)
        //     };
        //     request.send();
        // }
    
      return () => null
    }, [selectedCategory, productReducer.data])

    useEffect(()=>{

        if(services.length > 0){
            let listLimit = page*limit
            listLimit = services.length >= listLimit?listLimit:(services.length)
            setSortedService(services.slice((page*limit-limit), listLimit))
        }

    }, [page, services])
    

    useEffect(()=>{

        window.scrollTo(0,80)

    }, [sortedService])

    useEffect(()=>{

        if(selectedPrice) {

            const priceFilter = priceFilters.filter(item=>item.name === selectedPrice)[0]
            const newServices = services.filter((item)=>(
                parseFloat(item.Price) >= priceFilter.min && parseFloat(item.Price) < priceFilter.max
            ))
            if(newServices.length > 0){
                let listLimit = page*limit
                listLimit = newServices.length >= listLimit?listLimit:(newServices.length)
                setSortedService(newServices.slice((page*limit-limit), listLimit))
            }
            
        }

    }, [selectedPrice])

    
    useEffect(()=>{

        if(selectedFilter) {

            const _filter = filters.filter(item=>item.name === selectedFilter)[0]



            if(_filter.id === 'alph_desc') {
                setServices(
                    [
                        ...services.map(item=>({...item, title: item.title.replace("\n", "").trim()})).sort(function(a, b){
                            if(a.title < b.title) { return -1; }
                            if(a.title > b.title) { return 1; }
                            return 0;
                        })
                    ]
                )
            } else if(_filter.id === 'alph_asc') {

                setServices(
                    [
                        ...services.map(item=>({...item, title: item.title.replace("\n", "").trim()})).sort(function(a, b){
                            if(a.title < b.title) { return 1; }
                            if(a.title > b.title) { return -1; }
                            return 0;
                        })
                    ]
                )
            } else if(_filter.id === 'price_desc') {
                setServices(
                    [
                        ...services.sort(function(a, b){
                            if(a.Price < b.Price) { return 1; }
                            if(a.Price > b.Price) { return -1; }
                            return 0;
                        })
                    ]
                )
            } else if(_filter.id === 'price_asc') {
                setServices(
                    [
                        ...services.sort(function(a, b){
                            if(a.Price < b.Price) { return -1; }
                            if(a.Price > b.Price) { return 1; }
                            return 0;
                        })
                    ]
                )
            } 
        }

    }, [selectedFilter])

    
    useEffect(()=>{

        
        const prevTitle = document.title
        document.title =  capitalizeFirstLetter((selectedCategory || "").toLowerCase()) + " | Shop " + selectedCategory + " online | The Mecca store Kenya"
        return () => {
            document.title = prevTitle //when unmounting return back the original title header
        }
    }, [selectedCategory])
    

    return (
        <> 

            <div className="pb-2 mb-3 px-0" style={{paddingTop: '15px', }}>
                
                <div className="container py-2 mb-4 border-bottom">

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/" style={{fontSize: '14px'}}>
                            Home
                        </Link>
                        <Typography color="textPrimary" style={{fontSize: '14px'}} >
                            {category?category:"Shop All"}
                        </Typography>
                    </Breadcrumbs>
                    {/* <div className="" style={{marginBottom: '50px'}}>
                        <h2 className="text-center text-capitalize" style={{color: '#000', fontSize: '45px'}}>
                            {category?category:"Shop All"}
                        </h2>
                        <div className="w-100 text-center px-3 px-md-0" style={{wordBreak: 'break-word', width: '100%'}} >
                            <p>Explore through our rich shop and get to purchase top quality items of your choice.</p>
                        </div>
                        <div className='d-flex align-items-center justify-content-center pt-1 mt-4' >
                            
                            <input type="text" 
                                style={{borderRadius: '50px', border: '1px solid #d3d3d3', padding: '10px 20px', fontSize: '17px',}}
                                className={classes.input}
                                placeholder="Start typing to search..."
                                onChange={handleSearch}
                            />

                        </div>
                    </div>  */}

                </div>

                <div className="container px-0 px-md-auto">
                    

                    <Grid container spacing={2} >

                        <Grid xs={0} md={3} className="d-none d-md-block" >

                            <Box className='' style={{width: '90%'}}>
                                
                                <FormControl fullWidth component="fieldset" className='mt-3'>
                                    <div className="d-flex">
                                        <FormLabel component="legend" style={{color: '#000'}}>Category</FormLabel>
                                    </div>
                                    <RadioGroup aria-label="category" name="category" value={selectedCategory} onChange={handleCategoryChange}>
                                        {
                                            
                                            categories.map((item)=>(
                                                <FormControlLabel 
                                                    className='p-0 m-0' 
                                                    value={item.cat} 
                                                    key={item.cat} 
                                                    control={<Radio color="primary" size="small" />} 
                                                    label={ <Typography style={{fontSize: '14px'}}> {item.cat}</Typography>} 
                                                    style={{fontSize: '13px'}}
                                                />
                                            ))
                                        }
                                        
                                    </RadioGroup>
                                    <Divider className="mt-2" />
                                </FormControl>
                                
                                <FormControl fullWidth component="fieldset" className='mt-3'>
                                    <div className="d-flex">
                                        <FormLabel component="legend" style={{color: '#000', paddingTop: '6px'}}>Price</FormLabel>
                                        <div style={{flex: 1,}}></div>
                                        <Button size="small" color="primary" className="m-0" onClick={()=>setSelectedPrice("")}>
                                            Clear
                                        </Button>
                                    </div>
                                    <RadioGroup aria-label="category" name="category" value={selectedPrice} onChange={handlePriceChange}>
                                        {
                                            
                                            priceFilters.map((item)=>(
                                                <FormControlLabel 
                                                    className='p-0 m-0' 
                                                    value={item.name} 
                                                    key={item.name} 
                                                    control={<Radio color="primary" size="small" />} 
                                                    label={ <Typography style={{fontSize: '14px'}}> {item.name}</Typography>} 
                                                    style={{fontSize: '13px'}}
                                                />
                                            ))
                                        }
                                        
                                    </RadioGroup>
                                    <Divider className="mt-2" />
                                </FormControl>
                                
                                <FormControl fullWidth component="fieldset" className='mt-3'>
                                    <div className="d-flex">
                                        <FormLabel component="legend" style={{color: '#000', paddingTop: '6px'}}>Sort by</FormLabel>
                                        <div style={{flex: 1,}}></div>
                                        <Button size="small" color="primary" className="m-0" onClick={()=>setSelectedFilter("")}>
                                            Clear
                                        </Button>
                                    </div>
                                    <RadioGroup aria-label="category" name="category" value={selectedFilter} onChange={handleFilterChange}>
                                        {
                                            
                                            filters.map((item)=>(
                                                <FormControlLabel 
                                                    className='p-0 m-0' 
                                                    value={item.name} 
                                                    key={item.id} 
                                                    control={<Radio color="primary" size="small" />} 
                                                    label={ <Typography style={{fontSize: '14px'}}> {item.name}</Typography>} 
                                                    style={{fontSize: '13px'}}
                                                />
                                            ))
                                        }
                                        
                                    </RadioGroup>
                                    <Divider className="mt-2" />
                                </FormControl>

                            </Box>

                        </Grid>
                        <Grid xs={12} md={9}  >
                            <div>

                                <Grid container justify="center" align="center"  >
                                    {
                                        
                                        sortedService.length > 0?

                                            <Grid item xs={12}>

                                                <Grid container spacing={1}>


                                                    {

                                                        sortedService.map((item,index)=>(
                                                
                                                            <Grid item xs={6} md={3} key={index}>
                                                                <ServicesCard 
                                                                    size="sm"
                                                                    product={item}
                                                                />
                                                            </Grid>
                                                        ))
                                                    }
                                                </Grid>
                                            </Grid>
                                        
                                        :

                                            <div>
                                                <Avatar variant="square" style={{width: '110px', height: '110px', marginBottom: '15px' }}>
                                                    <ShoppingBasket style={{fontSize:'70px' }} />
                                                </Avatar>
                                                <p className='text-center' style={{fontSize: '20px'}}>
                                                    0 results found
                                                </p>
                                            </div>

                                    }
                                </Grid>
                                <div className='d-flex mt-2 mb-2'>
                                    <div style={{flex: 1}}></div>
                                    <Pagination 
                                        count={(services.length%limit > 0)?(Math.round(services.length/limit) + 1):Math.round(services.length/limit) } 
                                        page={page} 
                                        onChange={handlePageChange} 
                                        variant="outlined" 
                                        shape="rounded" 
                                        color="secondary"
                                        size="small"
                                    />
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                    

                </div>
                
            </div>
           
        
        </>
    )

}
