import { Avatar, Box, Button, Card, CardHeader, Divider, Grid, Link, Paper, Slide, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useStyles } from '../AboutMe/style'
import ServicesCard from './ServicesCard'
import allProducts from '../../Data/shop_data.xlsx'
import '../../utils/css/styles.css'
import * as xlsx from 'xlsx';
import { useNavigate } from 'react-router'
import { useStyles_ } from './style'
import cookwareOfferImg from '../../images/cookware1.png'
import { categories } from '../../utils/constants'
import { useSelector } from 'react-redux'

export default function ServicesContainer(props) {

    const productReducer = useSelector(state => state.productReducer)
    const [services, setServices] = useState([])
    const [featuredServices, setFeaturedServices] = useState([])
    const classes =useStyles ()
    const classes1 = useStyles_()
    const navigate = useNavigate()


    const featured = [
        "2729_Hp118_Belly_Shaped_2500_Ml_Bubble_Design",
        "Bt110-2563_Bread_Toaster_Black",
        "Dc270-2232_24cm_Diamond_Titanium_Casserole",
        "Dr110_Gold_Dishrack_2_Trays",
        "Pr014-2755_7l_Stainless_Steel_Pressure_Cooker_With_Glass_Lid",
        "Sm150-1977_Spin_Mop",
        "Sm150-1980_Deep_Clean_Cloth",
        "Tm786gs_24pcs_Cutlery_Set",
        "Vc270-1368_Digital_Vacuum_Cleaner",
        "Wo395-616_32cm_Die-Casted_Wok_Pan",
    ]


console.log(services)

    useEffect(() => {


        if(productReducer.data) {
            let json = productReducer.data
            const featured1 = featured.map((item)=>{
                const arr = json.filter(service=>service.title.replace('\n', '').trim().toLowerCase() === item.replaceAll('_', " ").toLowerCase())
                return arr.length  > 0? arr[0]:[]
            })

            setFeaturedServices(featured1.filter(item=>!Array.isArray(item)))//remove empty data too from featured

            // console.log(json.slice(0,5).map(item=>item.title.replace('/n', '').trim().toLowerCase()))
            // console.log(featured.slice(0,5).map(item=>item.replaceAll('_', " ").toLowerCase()))
            setServices([...json])
        }

        // if (allProducts) {
        //     var request = new XMLHttpRequest();
        //     request.open('GET', allProducts, true);
        //     // request.responseType = 'blob';
        //     request.responseType = "arraybuffer";
        //     request.onload = function() {


                
        //         /* convert data to binary string */
        //         var data = new Uint8Array(request.response);
        //         var arr = new Array();
        //         for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //         var excelFile = arr.join("");

        //         const workbook = xlsx.read(excelFile, { type: "binary" })
        //         const sheetName = workbook.SheetNames[0]
        //         const worksheet = workbook.Sheets[sheetName]
        //         const json = xlsx.utils.sheet_to_json(worksheet)
        //         // console.log(json)
        //         const featured1 = featured.map((item)=>{
        //             const arr = json.filter(service=>service.title.replace('\n', '').trim().toLowerCase() === item.replaceAll('_', " ").toLowerCase())
        //             return arr.length  > 0? arr[0]:[]
        //         })

        //         setFeaturedServices(featured1.filter(item=>!Array.isArray(item)))//remove empty data too from featured

        //         // console.log(json.slice(0,5).map(item=>item.title.replace('/n', '').trim().toLowerCase()))
        //         // console.log(featured.slice(0,5).map(item=>item.replaceAll('_', " ").toLowerCase()))
        //         setServices(json)
        //     };
        //     request.send();
        // }
    
      return () => null
    }, [productReducer.data])


    return (

        
        <div className='mt-4'>


            {/* categories */}
            <section style={{background: 'inherit'}}  className="mb-4 rounded p-2">
                {/* <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'normal', color: '#000'}}> Shop by category </Typography> 
                        </div>
                    </div>
                    <Divider /> 
                </div> */}
                
                <Grid container spacing={1} className="" > 

                    <Grid item xs={12} md={12} style={{height: '100%'}}>
                        
                        <div className={`d-flex w-100 align-items-center ${classes1.catDiv}`}  style={{ overflowX: 'auto', minHeight : '130px', background: 'inherit'}}>

                            {
                                categories.map((item)=>(
                                    <div 
                                        onClick={()=>{navigate('/shop/'+item.catUrl)}} 
                                        // item 
                                        // xs={12} 
                                        // md={3} 
                                        key={item["cat"]} 
                                        style={{marginBottom: '8px'}}
                                        className={`${classes1.catCard} catCard`}
                                    >

                                        <Box className='h-100 ' style={{borderRadius: '15px', border: '1px solid #d3d3d3', width: '100%', cursor: 'pointer', background: '#eaeded'}} >
                                            <div className="position-absolute w-100" style={{borderRadius: '15px', background: 'rgba(0,0,0,.3)', top: 0, bottom: 0 }}></div>
                                            <Box className="w-100 h-100">
                                                <img 
                                                    className={classes1.catCardImg}
                                                    style={{backgroundColor: '#fcb800', objectFit: 'cover', borderRadius: '15px'}}
                                                    src={item.img} 
                                                />
                                            </Box>
                                            <Box
                                                className={`${classes1.catTextContainer}  w-100 d-flex justify-content-center align-items-center  text-center`}
                                                
                                            >
                                                
                                                <Typography variant="h3" className={classes1.catText}>
                                                    {item.cat}
                                                </Typography>

                                            </Box>
                                        </Box>

                                    </div>
                                ))
                            }
                        </div>
                    </Grid>              
                </Grid>
            </section>
            {/* new arrivals */}
            <section style={{background: '#fff', borderRadius: '8px',}}  className="mb-4 p-2" >

                <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'bold', color: '#000'}}> New arrivals </Typography> 
                        </div>
                        <div style={{flex: 1}}></div>
                        {/* <div>
                            <Link href="/shop/tableware" style={{lineHeight: '40px', color: '#000', textDecoration: 'underline'}} >View all</Link>
                        </div> */}
                    </div>
                    {/* <Divider />  */}
                </div>  
                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={1}>


                            {
                                services.length > 0?
                                    [
                                        107, 11, 115, 80, 71,26,
                                        // 90, 110, 200, 400, 327, 164
                                    ].map((item,index)=>(
                            
                                        <Grid item xs={6} md={2} key={index}>
                                            <ServicesCard 
                                                size={"lg"}
                                                product={services[item]}
                                            />
                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            {/* must have deals */}
            <section style={{background: '#fff', borderRadius: '8px',}}  className="mb-4 p-2" >

                <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'bold', color: '#000'}}> Must have deals</Typography> 
                        </div>
                        <div style={{flex: 1}}></div>
                    </div>
                    {/* <Divider />  */}
                </div>  
                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={1}>


                            {
                                featuredServices.length > 0?
                                
                                    featuredServices.slice(0,6).map((item,index)=>(
                            
                                        <Grid item xs={12} md={4} sm={6} key={index}>
                                            <Card className="position-relative" >
                                                <svg className="svg" fill="#e6a800" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 10" preserveAspectRatio="none">
                                                    <polygon points="0 0 30 0 10 25 0 10" />
                                                </svg>
                                                
                                                <div className='position-absolute' style={{top: '10%'}}>

                                                    <div className='d-flex align-items-center'>
                                                        <div className="d-flex align-items-center" style={{width: '35%'}}>
                                                            <Avatar 
                                                                variant="square" 
                                                                className={`${classes1.mustHaveImg} ml-md-3 ml-2`}
                                                                // src={"https://" + item.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item1=> item1 !== "\n")[0]}
                                                                src={require('../../images/featured/'+item.title.trim().toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()).replaceAll(' ', '_')+'.png')}
                                                            />
                                                        </div>
                                                        <div className="ml-4 ml-md-3 px-2" style={{width: '60%'}}>
                                                            <p className={classes1.mustHaveTxt} style={{color: '#fff',  textTransform: 'capitalize'}}>
                                                                {
                                                                    item.title.toLowerCase()
                                                                    
                                                                }
                                                            </p>

                                                            <Button 
                                                                color="primary" 
                                                                variant="contained" 
                                                                style={{marginTop: '5px', color: '#fff', textTransform: 'none'}}
                                                                onClick={()=>window.open('/products/'+item.title.replace('\n', '').trim().toLowerCase(), '_self')}
                                                            >
                                                                Shop Now
                                                            </Button>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Card>

                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            {/* Popular products */}
            <section style={{background: '#fff', borderRadius: '8px',}} className="mb-4 p-2">
            
                <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'bold', color: '#000'}}>  Popular products</Typography> 
                        </div>
                        <div style={{flex: 1}}></div>
                        {/* <div>
                            <Link href="/shop" style={{lineHeight: '40px',color: '#000', textDecoration: 'underline'}} >View all</Link>
                        </div> */}
                    </div>
                    {/* <Divider />  */}
                </div>         
                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={2}>


                            {
                                services.length > 0?
                                services
                                    .sort((a,b)=>(parseInt(a.Price) - parseInt(b.Price)))
                                    .filter((item, index)=>[10, 30, 55, 100, 27, 85].includes(index))
                                    .map((item,index)=>(
                            
                                        <Grid item xs={6} md={2} key={index}>
                                            <ServicesCard 
                                                size={"md"}
                                                product={item}
                                            />
                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>

            {/* cooking appliances */}
            <section style={{background: '#fff', borderRadius: '8px',}} className="mb-4 p-2">
            
                <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'bold', color: '#000'}}> Cooking appliances </Typography> 
                        </div>
                        <div style={{flex: 1}}></div>
                        <div>
                            <Link href="/shop/cooking-appliances-1" style={{lineHeight: '40px',color: '#000', textDecoration: 'underline'}} >View all</Link>
                        </div>
                    </div>
                    {/* <Divider />  */}
                </div>         
                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={2}>


                            {
                                services.length > 0?
                                services
                                    // .slice(300)
                                    .filter(service=>service.category.toLowerCase().replace("/", "") === "cooking-appliances-1".toLowerCase())
                                    // .slice(0, 12)
                                    .slice(0, 6)
                                    .map((item,index)=>(
                            
                                        <Grid item xs={6} md={2} key={index}>
                                            <ServicesCard 
                                                size={"md"}
                                                product={item}
                                            />
                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            {/* cookware banner */}
            <section style={{backgroundImage: 'linear-gradient(to left, #e6a800, #329bb3)', borderRadius: '8px',}}  className={"mb-4 p-2"} >
                
                <div className='d-block d-md-flex position-relative' >
                    
                    <div className={`${classes1.bannerContent} d-flex justify-content-center align-items-center`} >
                        
                            <div>
                                <div className={`${classes1.bannerDoubleLines} mb-4`} style={{ height: '7px', borderTop: '2px solid white', borderBottom: '2px solid white', }}></div>
                                <h2 style={{color: '#fff', }} className={classes1.bannerTitle}>
                                    UP TO 5% OFF <br />
                                    COOKWARE CATEGORY
                                </h2>
                                <p className={classes1.bannerDes} style={{color: '#fff', fontSize: '12px'}}>
                                    COOKWARE ITEMS ARE UPDATED REGULARLY WITH OFFERS
                                </p>
                                <div className={`${classes1.bannerDoubleLines} mt-4`} style={{ height: '7px', borderTop: '2px solid white', borderBottom: '2px solid white', }}></div>
                               <div className={classes1.bannerDes} >
                                    <Button onClick={()=>window.open('/shop/cookware', '_self')} color="primary" variant="contained" style={{marginTop: '20px', color: '#fff', borderRadius: 'px', textTransform: 'none'}}>
                                        Shop Now
                                    </Button>
                                </div>
                            </div>
                        
                    </div>
                    <div className={`${classes1.bannerContent2} d-flex`} >

                        <Avatar src={cookwareOfferImg} variant="square" className='rounded w-100' style={{height: '350px'}} />
                        
                    </div>
                </div>
            </section>
            {/* curtlery */}
            <section style={{background: '#fff', borderRadius: '8px',}} className="mb-4 p-2">
            
                <div className='py-2 mb-2'>
                    <div className="d-flex px-2">
                        <div>
                            <Typography style={{fontSize: '24px', fontWeight: 'bold', color: '#000'}}>  Tableware and Cutlery </Typography> 
                        </div>
                        <div style={{flex: 1}}></div>
                        <div>
                            <Link href="/shop/tableware" style={{lineHeight: '40px',color: '#000', textDecoration: 'underline'}} >View all</Link>
                        </div>
                    </div>
                    {/* <Divider />  */}
                </div>         
                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={2}>


                            {
                                services.length > 0?
                                services                                    
                                    .sort((a,b)=>(parseInt(a.Price) - parseInt(b.Price)))
                                    // .slice(0, 12)
                                    .slice(40, 46)
                                    .map((item,index)=>(
                            
                                        <Grid item xs={6} md={2} key={index}>
                                            <ServicesCard 
                                                size={"md"}
                                                product={item}
                                            />
                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            {/* just for you */}
            <section style={{background: 'inherit', borderRadius: '8px',}}  className="mb-4 p-0" >

                <Grid container justify="center" align="center"  >
                    
                    <Grid item xs={12}>

                        <Grid container spacing={1}>


                            
                        {
                                featuredServices.length > 0?
                                
                                    featuredServices.slice(4, 6).map((item,index)=>(
                            
                                        <Grid item xs={12} md={6} key={index}>
                                            <Card className="p-0 m-0" style={{
                                                background: index === 0?"#66a652":"#329bb3",
                                                color: '#fff'
                                            }} >
                                                

                                                <CardHeader
                                                    title={index === 0?"Just for you":"Must check out"}
                                                    titleTypographyProps={
                                                        {style: {textAlign: 'left', fontSize: '24px', fontWeight: 'bold', color: 'inherit'}}
                                                    }
                                                />
                                                <div className='mt-2'>

                                                    <div className='d-flex align-items-center position-relative'>
                                                        <div className={`${classes1.justForYouSect1} d-flex align-items-center pr-2`}>
                                                            <Avatar 
                                                                variant="square" 
                                                                className="ml-3"
                                                                style={{width: 'auto', height: '350px'}}
                                                                // src={"https://" + item.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item1=> item1 !== "\n")[0]}
                                                                // src={require('../../images/featured/'+item.title.trim().toLowerCase().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()).replaceAll(' ', '_')+'.png')}
                                                                src={require('../../images/featured/'+featured[featured.map(item=>item.toLowerCase()).indexOf(item.title.trim().replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase()).replaceAll(' ', '_').toLowerCase())]+'.png')}
                                                            />
                                                        </div>
                                                        <div className={`${classes1.justForYouSect2} ml-0 ml-md-3 px-2 ml-0`}>
                                                            <p className={classes1.justForYouSect2_p} style={{color: 'inherit',  textTransform: 'capitalize'}}>
                                                                {
                                                                    item.title.toLowerCase()
                                                                    
                                                                }
                                                            </p>

                                                            <Button 
                                                                color="primary" 
                                                                size="small" 
                                                                variant="contained" 
                                                                style={{ color: '#fff', textTransform: 'none'}}
                                                                onClick={()=>window.open('/products/'+item.title.replace('\n', '').trim().toLowerCase(), '_self')}
                                                            >
                                                                Shop Now
                                                            </Button>

                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Card>

                                        </Grid>
                                    ))
                                :""
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </section>

        </div>        

    )
    
}
