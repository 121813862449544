import React, {useEffect, useState} from 'react';
import { Avatar, List, ListItem, ListItemText, ListItemIcon, Button, Box, TextField } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useStyles } from './style';
import logo from '../../images/mecca-logo.jpg'
import { Link, Drawer, Grid, Divider } from '@material-ui/core';
import {  AppName,appBarItems, PHONE_NUMBER, categories, capitalizeFirstLetter } from '../../utils/constants'
import { Close, Email, Facebook, Help, HelpOutline, Instagram, KeyboardArrowDown, LocalPhone, LocationOn, PhoneAndroid, PhoneInTalk, ShoppingCart, Twitter } from '@material-ui/icons';
import { useNavigate } from 'react-router';
import allProducts from '../../Data/shop_data.xlsx'
import * as xlsx from 'xlsx';


export default function AppBarComponent() {
  const classes = useStyles();
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [productTitles, setProductTitles] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const navItemsColor = ["#202c33", "#329bb3", "#610d8f", "#b5993d" ]

  const toggleDrawer = () => {
      setDrawerOpen(!drawerOpen)
  }
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLinkClick = (e, url)=> {
    e.preventDefault()
    setDrawerOpen(false)
    navigate(url)
    
  }

  const handleChange = (e) => {
    setSearchQuery(e.target.value)
  }

  const handleSearch = () => {
    if(searchQuery) {

      setSearchResults(productTitles.filter(item=>item.indexOf(searchQuery) > -1))

    }else {
      setSearchResults([...[]])
    }
  }

  
  useEffect(() => {

    if (allProducts) {
        var request = new XMLHttpRequest();
        request.open('GET', allProducts, true);
        // request.responseType = 'blob';
        request.responseType = "arraybuffer";
        request.onload = function() {


            
            /* convert data to binary string */
            var data = new Uint8Array(request.response);
            var arr = new Array();
            for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
            var excelFile = arr.join("");

            const workbook = xlsx.read(excelFile, { type: "binary" })
            const sheetName = workbook.SheetNames[0]
            const worksheet = workbook.Sheets[sheetName]
            let json = xlsx.utils.sheet_to_json(worksheet)

            
            setProductTitles(json.map(service=>service.title.replace('/n', '').trim().toLowerCase()))
        };
        request.send();
    }

    return () => null
  }, [])

  useEffect(() => {
    handleSearch()
  }, [searchQuery])


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="10 items in shopping cart" color="inherit">
          <Badge badgeContent={10} color="secondary">
            <ShoppingCart />
          </Badge>
        </IconButton>
        <p>Shopping Cart</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar style={{padding: '15px',borderBottom: '1px solid #fff '}}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <a href="/"><img src={logo} className={classes.avatar} /></a>
          <Typography className={classes.title} variant="h6" noWrap>
            {AppName}
          </Typography> 

          <div className={classes.grow} />

          <div>

            <div className='d-flex align-items-center'>
              <div className='position-relative'>
                <input
                  onChange={handleChange}
                  placeholder="I'm shopping for..."
                  style={{
                    marginTop: '2px',
                    border: '1px solid #d3d3d3',
                    padding: '0 20px'
                  }}
                  className={`${classes.searchBar} shadow-none`}
                />

                {
                  searchResults.length > 0?

                    <div 
                      style={{
                        position: 'absolute',
                        width: '96.5%',
                        left: '3.5%',
                        top: '100%',
                        background: '#fff',
                        boxShadow: '0 4px 8px 0 #d3d3d3, 0 6px 20px 0 #d3d3d3',
                        zIndex: 10,
                        cursor: 'pointer'
                      }}
                    >

                      {
                        searchResults.slice(0,5).map((item)=>(
                          <div 
                            key={item} 
                            className={`${classes.searchList} py-2 px-2 border-bottom`}
                            onClick={()=>window.open('/products/'+item.toLowerCase(), '_self')}
                          >
                            { capitalizeFirstLetter(item)}
                          </div>
                        ))
                      }

                    </div>
                    
                  :""
                }
              </div>

              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleSearch}
                style={{
                  color: '#fff',
                  height: '45px', 
                  borderTopLeftRadius: '0',
                  borderBottomLeftRadius: '0',
                  borderTopRightRadius: '30px',
                  borderBottomRightRadius: '30px',
                }}
                className="d-none d-md-block"
              >
                Search
              </Button>


            </div>

          </div>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Box>
              <IconButton
                  onClick={()=>window.open('tel:+'+PHONE_NUMBER, '_blank')}
                  style={{padding: 8, background: "#00acee", margin: 5}}
              >
                  <PhoneInTalk style={{fontSize: '16px', color: '#fff'}}/>
              </IconButton >
              <IconButton
                  onClick={()=>window.open('https://www.facebook.com/themeccastores', '_blank')}
                  style={{padding: 8, background: "#4267B2", margin: 5}}
              >
                  <Facebook style={{fontSize: '16px', color: '#fff'}}/>
              </IconButton >
              <IconButton 
                  onClick={()=>window.open('https://www.instagram.com/themecca_store/shop', '_blank')}
                  style={{padding: 8, background: "#f54e41", margin: 5}}
              >
                  <Instagram style={{fontSize: '16px', color: '#fff'}}/>
              </IconButton>
            </Box> 
            {/* <Button startIcon={<Phone />} onClick={()=>window.open('tel:+'+PHONE_NUMBER)} variant='contained' color='primary' size='large' style={{borderRadius: '5px', color: '#fff'}}>
              Call Us Now
            </Button> */}
          </div>
          {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div> */}
        </Toolbar>
        
        <Divider style={{backgroundColor: '#d3d3d3',}} />
         
        <Box className='d-none d-md-flex 'style={{backgroundColor: '#fff', fontSize: '15px'}}>
          <div style={{flex:"1"}}></div>
          <Box className='d-flex dropdown text-uppercase position-relative' style={{height: '50px', cursor: 'pointer' }} >
            <div className='d-flex align-items-center appBarDropdown' >
              <div style={{fontSize: '17px'}} className='d-flex align-items-center'>
                <MenuIcon style={{marginRight: '5px', fontSize: '27px'}} />
                <strong>Shop by category </strong>
              </div>

              <div className="drop-down" 
                style={{
                  position: 'absolute',
                  top: '100%',
                  left: '-20%',
                  // marginTop: '14px',
                  zIndex: 20,
                  height: 'auto',
                  width: '300px',
                  background: 'transparent',
                }}
              >

                <div className="position-relative">
                  <div 
                    className='content m-1 mt-2 rounded'
                    style={{
                      background: '#fff',
                      boxShadow: '0 4px 8px 0 #e1e6ed, 0 6px 20px 0 #e1e6ed'
                    }}
                  
                  >

                  
                    <List component="nav" aria-label="main mailbox folders">

                      {
                          categories.map((item, index)=>(

                            <ListItem button key={item.cat} onClick={()=>{window.location.href = ('/shop/'+item.catUrl)}} >
                              <Box color="inherit" >

                                <div className="d-flex align-items-center">
                                  <Avatar
                                    src={item.img} 
                                    variant="square"
                                    style={{width: '50px', height: '50px', background: '#eaeded'}}
                                    className="rounded mr-2"
                                  />
                                  <span style={{fontSize: '14px' }} >
                                    {item.cat}
                                  </span>
                                </div>
                              </Box>
                            </ListItem>
                          ))
                      }
                    </List>

                  </div>
                </div>

              </div>
            </div>
          </Box>
          <div style={{flex:"1"}}></div>
          <Box className='d-flex justify-content-center align-items-center' style={{height: '50px' }}>
            
            
            {/* <div className={classes.linksContainer}> */}

            {
                appBarItems.filter(item=>item.name !== "Shop all").map((item, index)=>(

                    <Box  onClick={(e)=>handleLinkClick(e,item.url)} color="inherit" className={`${classes.link} px-3 d-flex align-items-center`} key={index}>
                        <span style={{fontSize: '18px', marginRight: '5px', color: navItemsColor[index]}} >
                          {item.icon}
                        </span>
                        <span>
                         {item.name}
                        </span>
                    </Box>
                ))
            }

            {/* </div>   */}
          </Box>
          {/* <div style={{flex:"1"}}></div> */}
          <Box className='d-flex text-uppercase align-items-center' style={{height: '50px' }}>
            <Box 
              color="inherit"  
              className={`${classes.link} px-3 d-flex align-items-center`}
              onClick={()=>window.open('tel:+'+PHONE_NUMBER, '_blank')}
            >
              <PhoneInTalk style={{fontSize: '22px', marginRight: '5px', color: '#50ad55'}}/> 0735280469
            </Box>
            <Box 
              color="inherit"
              className={`${classes.link} px-3 d-flex align-items-center`}
              onClick={()=>window.open('mailto:info@meccastore.co.ke?subject=Help and Support&body=Hello mecca stores, Am contacting to enquire about ...(write your question here) ', '_blank')}
              
            >
              <HelpOutline style={{fontSize: '22px', marginRight: '5px', color: '#f54e41'}}/> HELP & support
            </Box>
            <Box 
              color="inherit"
              className={`${classes.link}  px-3 d-flex align-items-center`}
              onClick={()=>window.open('https://www.facebook.com/themeccastores', '_blank')}
            >
              <Facebook style={{fontSize: '22px', marginRight: '5px', color: '#4267b2'}}/> Follow us
            </Box>
            <Box 
              color="inherit"
              className={`${classes.link} px-3 d-flex align-items-center`}
              onClick={()=>window.open('https://www.instagram.com/themecca_store/shop', '_blank')}
            >
              <Instagram style={{fontSize: '22px', marginRight: '5px', color: '#f6017e'}}/> Like our page
            </Box>
          </Box>
          {/* <div style={{flex:"1"}}></div>
          <Box>
            <IconButton style={{padding: 8, background: "#4267B2", margin: 5}}>
              <Facebook style={{fontSize: '16px', color: '#fff'}}/>
            </IconButton >
            <IconButton style={{padding: 8, background: "#f54e41", margin: 5}}>
              <Instagram style={{fontSize: '16px', color: '#fff'}}/>
            </IconButton>
            <IconButton style={{padding: 8, background: "#00acee", margin: 5}}>
              <Twitter style={{fontSize: '16px', color: '#fff'}}/>
            </IconButton>
          </Box> */}

        </Box>

      </AppBar>

    <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer}  
      classes={{ paper: classes.drawer }}>

        <Grid container style={{margin: '10px 0'}}>
            <Grid item xs={9}>
                <Typography variant="h6" className={classes.drawerTitle}>
                    {AppName}
                </Typography>

            </Grid>
            <Grid item xs={3}>
              <IconButton onClick={toggleDrawer}>
                  <Close />
              </IconButton>
                
            </Grid>
            <Grid item xs={12}>
            <Divider />
            </Grid>
        </Grid>
        <List>
            {
                appBarItems.map((item, index)=>(

                    <ListItem button key={index} onClick={(e)=>handleLinkClick(e,item.url)}>
                        <ListItemIcon >{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name}/>
                    </ListItem>
                ))
            }
        </List>
    </Drawer>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
