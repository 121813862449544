
import { fade, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    grow: {
      flexGrow: 1,
    },
    appBar: {
        backgroundColor: '#fff',
        color: '#000',
        padding: '0',
        
    },
    drawer: {
      width: '250px',
    },
    menuButton: {
      marginRight: theme.spacing(2),
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      }
    },
    avatar: {
        marginRight: '10px',
        width: theme.spacing(9),
        height: theme.spacing(8),
    },
    title: {
        textTransform: 'uppercase',
        display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    linksContainer: {

        padding: '0 10px',
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
    },
    link: {
        padding: '0 30px',
        // margin: '0 25px',
        textTransform: 'uppercase',
        borderRight: '1px solid #d3d3d3',
        cursor: 'pointer',
        '&:hover': {
          // textDecoration: 'underline',
          color: '#fcb800',
        },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.black, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.black, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchBar: {
      width: '500px',
      borderTopLeftRadius: '30px',
      borderBottomLeftRadius: '30px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      height: '45px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        borderRadius: '30px',
        height: '40px',
      }
    },
    searchList: {
      '&:hover': {
        backgroundColor: '#d3d3d3',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawerTitle: {
        padding: '10px 20px'
    },
    list: {
        width: 250,
    },
  }));