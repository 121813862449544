import React, {useEffect, useState } from 'react'
import { useStyles } from '../views/Home/style'
import { Paper } from '@material-ui/core'
import AppBar from '../components/AppBar'
import { Outlet } from 'react-router'
import Footer from '../components/Footer'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import { API_URL } from '../utils/appConfigs'
import { setAllProducts } from '../store/Products/productSlice'

export default function MainTheme(props){
    
    const classes =  useStyles()
    const dispatch = useDispatch();

    useEffect(() => {
      
        const headers = {
            "Content-Type": "application/json",
        }
        // const formData = new FormData()
        // formData.append('totalPrice', totalPrice);
    
        //axios.post(stripePaymentIntentRestUrl , formData, {headers:headers})
       
        axios.get(API_URL + "products?per_page=200", {headers:headers})
            .then((data)=>{

                dispatch(setAllProducts(data.data.data))
            })
            .catch((err)=>{
                console.log("error", err)
            })
    
      return () => null
    }, [])
    
   
    return (
        <div className={classes.root} style={{backgroundColor: 'inherit'}} >

            <Paper className={classes.mainComponent} style={{backgroundColor: 'inherit'}}>

                <AppBar />
                
                <Outlet />

                <Footer />
            </Paper>
        
        </div>
    )

}
