import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Box, Button, Tooltip } from '@material-ui/core';
import ViewProductModal from '../ViewProductModal';
import { API_URL } from '../../utils/appConfigs';

const useStyles = makeStyles({
  root: {
    marginBottom: '20px',
    '&:hover': {
      border: '1px solid #d3d3d3'
    }
  },
  media: {
    height: 180,
  },
});

export default function ServicesCard(props) {
  const classes = useStyles()
  const {product, size } = props
  
  // console.log(product)
  

  return (
    // <ViewProductModal product={product} classes={classes.root} style={{width: size === "lg"?300:200}}>

    <Card 
      style={{boxShadow: 'none'}}
      onClick={()=>window.open('/products/'+product.title.replace('\n', '').trim().toLowerCase(), '_self')}
    >
      
        <Tooltip title={product.title}>
          <CardActionArea className='p-1 border-0' style={{cursor: 'pointer', outline: 'none'}}>
            <CardMedia
              style={{height: 180}}
              image={
                product && product.prod_images?
                    product.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item=> item !== "\n")[0].indexOf("cdn.shopify.com") > -1?

                        "https://" + product.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item=> item !== "\n")[0]
                    :
                    API_URL.replace("api/", "") + "storage/products/" + product.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item=> item !== "\n")[0]
                :
                    null
              } 
              title={product.title}
            />
            <CardContent>
              <Typography className="text-truncate" variant="body2" color="black" component="p" style={{width: '100%',marginTop: '10px', fontSize: '13px', fontFamily: 'montessarat', textTransform: 'capitalize', textAlign: 'center'}}>
                {product.title.toLowerCase()}
              </Typography>
              <Typography gutterBottom variant="h5" color="secondary" component="h2" style={{fontSize: '15px', marginTop: '5px'}}>
                Kshs. {(product.Price || 0)}
              </Typography>
              {/* <Button color="primary" size="medium" variant='contained' style={{borderRadius: '20px', marginTop: '10px', color: "#fff", textTransform: 'capitalize'}}>
                Shop now
              </Button> */}
              {/* <Button variant="contained" size="small" color="primary"style={{borderRadius: '5px', marginTop: '7px', color: "#fff", textTransform: 'none'}}>
                  Shop now
              </Button> */}
              {/* <ViewProductModal product={product} open={openViewProductModal} handleClearParentOpen={handleClearParentOpen} />   */}
            </CardContent>
          </CardActionArea>
        
        </Tooltip>
    {/* </ViewProductModal> */}
    </Card>
  );
}
