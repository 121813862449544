
import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({

   
    input: {
        width: '40%',
        [theme.breakpoints.down('md')]: {
            
            width: '95%',
        }
    },

}))