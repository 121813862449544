import { Collections, ContactMail, Home, Info, InfoOutlined, LocalHospital, MailOutline, Shop } from "@material-ui/icons";

import c_kitchen from '../images/c-kitchen.jpg'
import cookware from '../images/cookware.jpeg'
import h_care from '../images/h_care.jpg'
import tableware from '../images/tableware.jpg'
import kitchen_app from '../images/kitchen-app.jpg'


export const PHONE_NUMBER = '254735280469'
export const AppName = "The Mecca Store"
export const appBarItems = [
    {
        name: 'Home',
        url: '/',
        icon: <Home />
    },
    
    {
        name: 'Shop all',
        url: '/shop',
        icon: <Shop />
    },
    // {
    //     name: 'Gallery',
    //     url: '/gallery',
    //     icon: <Collections />
    // },
    {
        name: 'About Us',
        url: '/about',
        icon: <InfoOutlined />
    },
    {
        name: 'Contact Us',
        url: '/contact',
        icon: <MailOutline />
    },
]

export const categories = [

    {
        cat: 'Tableware',
        img: tableware,
        catUrl: 'tableware',
    },
    {
        cat: 'Cookware Kitchenware',
        img: c_kitchen,
        catUrl: 'cookware-kitchenware',
    },
    {
        cat: 'Cookware',
        img: cookware,
        catUrl: 'cookware',
    },
    {
        cat: 'Cooking Appliances',
        img: kitchen_app,
        catUrl: 'cooking-appliances-1',
    },
    {
        cat: 'Home Care',
        img: h_care,
        catUrl: 'home-care',
    },
]

export const capitalizeFirstLetter = (string) => (string.charAt(0).toUpperCase() + string.slice(1).toLowerCase())