import React, {useEffect, useState } from 'react'
import { useStyles } from './style'
import { Paper, Grid, Tabs, Tab, Typography, Divider } from '@material-ui/core'
import AppBar from '../../components/AppBar'
import Carousel from '../../components/Carousel'
import ProductCard from '../../components/ProductCard'
import Button from '../../components/Button'
import AboutMe from '../../components/AboutMe'
import BookAppointment from '../../components/BookAppointment'
import ServicesContainer from '../../components/Services'

export default function Home(props){
    
    const classes =  useStyles()
    const [tabValue, setTabValue] = useState(0)
    const tabsData = ["New Arrivals", "Top Products", "Special Products"]
    
    const handleTabChange = (event, value)=> {
        setTabValue(value)
    }

    
    

    return (
        <div className='pb-4' style={{background: '#eaeded'}}>
            <Carousel />

            <div className='px-0 px-md-5' style={{background: 'inherit'}}>

                <ServicesContainer hideTitle={false} showAll={false} />

                
                <AboutMe />

            </div>
                
            
            {/* <DoctorsCarouselComponent /> */}

            {/* <div style={{marginTop: 30}}>

                <Typography variant={"h6"} className={classes.topHeader}>
                    My Products
                </Typography>
                <Typography variant={"h4"} className={classes.headers}>
                    Shop By Category
                </Typography>

                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    {
                        tabsData.map((item, index)=><Tab label={item} key={index} />)
                    }
                </Tabs>
                    
                <div className={classes.tabPanel}>
                    <Grid container style={{marginBottom: '70px'}}>

                        {
                            newProducts.map((item, index)=>(

                                
                                <Grid item xs={6} md={3}  key={index} style={{marginTop: '15px'}}>
                                    <ProductCard image={item.image} productName={item.name} productPrice={item.price}/>
                                </Grid>
                            ))
                        }

                            <Grid item xs={12} className={classes.viewMore}>
                            <Grid container justify="center" align="center" >
                                <Grid item xs={12}>
                                    <Button color="primary" text={"View More On Shop"} />
                                </Grid>
                            </Grid>
                        </Grid> 

                        

                    </Grid>


                </div>
            </div> */}



            {/* <BookAppointment /> */}
        </div>
    )

}
