

import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({

    contactForm: {
        position: 'absolute',
        left: '50%',
        width: '550px',
        top: '10%',
        marginTop: '0',
        boxShadow: '0 20px 20px 0 #d3d3d3, 0 6px 20px 0 #d3d3d3',
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            left: '0',
            width: '100%',
            top: '0',
            boxShadow: 'none',
            marginTop: '10px'
        }
    }, 
    contactMap: {
        width: '100%', 
        height: '800px', 
        border: 0,
        [theme.breakpoints.down('md')]: {
            height: '400px', 
        },
    },
}))