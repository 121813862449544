

import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: '#fff',
        padding: '10px 0 50px 0',
        marginBottom: '0',
        borderRadius: '8px',
        
    },
    container1: {
        
    },
    title:{
        padding: '15px',
        textAlign:'center',
        fontWeight: 'bold',
        fontSize: '25px',
        margin: "0 0 5px 0",
        color: '#000',
        [theme.breakpoints.down('md')]: {
            fontSize: '30px',  
        },
    },
    image: {
            
        width: '100%',
        height: '400px',      
        [theme.breakpoints.down('md')]: {
            marginTop: '0', 
            height: '280px',  
        },
    },
    content: {
        padding: '10px 50px'
    },
    contentText: {
        fontSize: '18px',
        fontFamily: 'Montserrat',
        lineHeight: '50px',
        [theme.breakpoints.down('md')]: {
            lineHeight: '35px',
            fontSize: '15px',
        }
    }
}))


    