
import { makeStyles } from '@material-ui/core/styles';



export const useStyles_ = makeStyles((theme) => ({

    catDiv: {
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'initial',
        },

    },
    catCard: {
        width: '235px', 
        height: '235px',
        marginRight: '10px',
        position: 'relative',
        background: '#eaeded',
          
      [theme.breakpoints.down('md')]: {
        width: '70px', 
        height: '70px', 
      },
    },
    catCardImg: {
        width: '235px', 
        height: '235px',
        [theme.breakpoints.down('md')]: {
          width: '70px', 
          height: '70px', 
        },

    },
    catTextContainer : {
        position: 'absolute',
        top: '38%',
        zIndex: 10,
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            top: 0,

        }

    },
    catText: {
        color: '#fff', 
        textAlign: 'center', 
        padding: '10px', 
        textTransform: 'capitalize',
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '11px',
            color: '#000', 
        }
    },
    mustHaveImg: {
        width: '150px', height: '180px',
        marginTop: '0',
        [theme.breakpoints.down('md')]: {
            width: '120px', height: '140px',
            marginTop: '10px',
        },

    },
    mustHaveTxt: {
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '15px',
        },
    },
    bannerDoubleLines: {
        width: '360px',
        [theme.breakpoints.down('md')]: {
            width: '90%',
            marginLeft: '5%',
        },
    },
    bannerContent: {
        position: 'relative',
        width: '50%',
        zIndex: '10',
        top: '0%',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            width: '100%',
            top: '15%',

        }

    },
    bannerContent2: {
        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }
    },
    bannerTitle: {
        fontSize: '25px',
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            fontSize: '27px',
            textAlign: 'center',
        },
    },
    bannerDes: {
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },

    },
    justForYouSect1: {

        width: '50%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    justForYouSect2: {

        position: 'relative',
        width: '48%',
        background: 'transparent',
        padding: '10px',
        [theme.breakpoints.down('md')]: {
            position: 'absolute',
            width: '100%',
            background: 'rgba(0,0,0,.7)',
            bottom: 0,
        },
    },
    justForYouSect2_p: {
        fontSize: '22px',
        marginBottom: '5px',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            marginBottom: '0',
        }
    }
    

}))