import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
  name: 'productSlice',
  initialState: {
    data: []
  },
  reducers: {
    setAllProducts: (state, action) => {
      state.data = [...action.payload]
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAllProducts } = productSlice.actions

export default productSlice.reducer