import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Box, Breadcrumbs, Card, Divider, Grid, IconButton, Link, Toolbar, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import CheckIcon from '@material-ui/icons/Check';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { capitalizeFirstLetter, PHONE_NUMBER } from '../../utils/constants';
import { ArrowBack } from '@material-ui/icons';
import allProducts from '../../Data/shop_data.xlsx'
import * as xlsx from 'xlsx';
import { useStyles } from './styles';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { API_URL } from '../../utils/appConfigs';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ProductPage(props) {


    const productReducer = useSelector(state => state.productReducer)
    const classes = useStyles()
    const { prodName } = useParams()
  const [openToast, setOpenToast] = React.useState(false)
  const [url, setUrl] = React.useState()
  const [product, setProduct] = React.useState({})

  const handleOrderNow = () => {
    let curHr = new Date().getHours()
    let time = "morning";

    if (curHr < 12) {
        time = "morning";
    } else if (curHr < 18) {
        time = "afternoon";
    } else {
        time = "evening";
    }
    const text = "Hello there, good " + time + ". I am contacting you to order product " + product.title.toLowerCase()
    + ". Please confirm if the the product is available and give me the go ahead on how to purchase and receive this product. Thanks."

    setOpenToast(true)
    const url = "https://api.whatsapp.com/send?phone="+PHONE_NUMBER+"&text=" + encodeURIComponent(text)
    const win = window.open(url, '_blank')
    // win.focus()
  }

  const getImgUrl = (_url) => {

    const newUrl = _url?
        _url.indexOf("cdn.shopify.com") > -1?

            "https://" + _url
        :
        API_URL.replace("api/", "") + "storage/products/" + _url
    :
        null

    return newUrl
  }
  const handleMoreImgClick = (_url) => {
    
    setUrl(getImgUrl(_url))
  }



  useEffect(() => {

        if(productReducer.data) {
            let json = productReducer.data
            json = json.filter(service=>service.title.replace('/n', '').trim().toLowerCase() === prodName.replaceAll('_', " ").toLowerCase())
            setProduct(json[0])
        }
        // if (allProducts && prodName) {
        //     var request = new XMLHttpRequest();
        //     request.open('GET', allProducts, true);
        //     // request.responseType = 'blob';
        //     request.responseType = "arraybuffer";
        //     request.onload = function() {


                
        //         /* convert data to binary string */
        //         var data = new Uint8Array(request.response);
        //         var arr = new Array();
        //         for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
        //         var excelFile = arr.join("");

        //         const workbook = xlsx.read(excelFile, { type: "binary" })
        //         const sheetName = workbook.SheetNames[0]
        //         const worksheet = workbook.Sheets[sheetName]
        //         let json = xlsx.utils.sheet_to_json(worksheet)

        //         //check if we are getting data for  category
                
        //         json = json.filter(service=>service.title.replace('/n', '').trim().toLowerCase() === prodName.replaceAll('_', " ").toLowerCase())
        //         setProduct(json[0])
        //     };
        //     request.send();
        // }

    return () => null
    }, [prodName, productReducer.data])


    

  useEffect(() => {
    
    if(product && product.prod_images) handleMoreImgClick(product.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item=> item !== "\n")[0])
  
    return () => null
  }, [product])


  useEffect(()=>{

    
    const prevTitle = document.title
    document.title = capitalizeFirstLetter((prodName || "").toLowerCase()) + " | Shop online | The Mecca store Kenya"
    return () => {
        document.title = prevTitle //when unmounting return back the original title header
    }
  }, [prodName])
  

  return (
    <div>

        
      <Box >

            <div className='pt-4'>
                {
                    product?


                        <Grid container style={{paddingBottom: '80px'}}>
                            <Grid item xs={12}>
                                <div className="container py-2 mb-4 border-bottom">

                                    <Breadcrumbs aria-label="breadcrumb">
                                        <Link color="inherit" href="/" style={{fontSize: '14px'}}>
                                            Home
                                        </Link>
                                        <Typography color="textPrimary" style={{fontSize: '14px'}} >
                                            {product?(product.title || "").toLowerCase():""}
                                        </Typography>
                                    </Breadcrumbs>

                                </div>
                            </Grid>
                            <Grid item md={5} xs={12} className="d-flex align-items-center justify-content-center mb-4 pb-2 px-2 pb-md-0 mb-md-0">
                                <div>
                                    <img src={url} className={classes.prodBannerImg} />
                                    <div className={classes.moreImgs}>
                                        {product && product.prod_images?
                                                product.prod_images.split('**PENGUINS_BREAK_POINT**').filter(item=> item !== "\n").map((item)=>(
                                                    <div className="">
                                                        <img 
                                                            src={getImgUrl(item)}
                                                            className={classes.otherImgs} 
                                                            style={{width: '60px', height: '60px', borderRadius: '8px'}}
                                                            onClick={()=>handleMoreImgClick(item)}
                                                        />
                                                    </div>
                                                ))
                                            :""
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <div className='container pr-4 mt-0'>
                                    <Typography variant='h5' className="mb-3" style={{textTransform: 'capitalize', fontFamily: 'times new roman', fontSize: '28px', fontWeight: 'normal'}}>
                                        <strong>{(product.title || "").toLowerCase()}</strong>
                                    </Typography>
                                    <Rating name="half-rating-read" defaultValue={parseInt(product.prod_ratings)} precision={0.5} readOnly />
                                    
                                    <h3 className='mb-2' style={{color: '#669900',fontWeight: 'bold', textTransform: 'capitalize', fontFamily: 'times new roman'}}>
                                        
                                        Kshs. {product.Price || 0}
                                    </h3>
                                    <div className='mt-3'>
                                        <Button
                                            onClick={handleOrderNow}
                                            startIcon={<AddShoppingCartIcon /> }
                                            variant="contained" 
                                            size="large" 
                                            color="primary"
                                            className={classes.orderBtn}
                                            style={{borderRadius: '5px', marginTop: '10px', color: "#fff", textTransform: 'capitalize'}}
                                        >
                                            Order now
                                        </Button>
                                    </div>


                                    {
                                        product.description?

                                        <>
                                            <Divider className="my-4" />
                                                
                                            <h5><strong>Description</strong></h5>
                                            <p style={{fontFamily: 'times new roman', fontSize: '17px', fontWeight: 'normal', lineHeight: '33px'}}>
                                            
                                                {product.description}

                                            </p>
                                        </>
                                        

                                        :""
                                    }
                                
                                    {
                                        product && product.specifications && product.specifications.split('**PENGUINS_BREAK_POINT**').filter(item=>item && item !== "\n").length > 0?


                                            
                                            <div className='mt-3'>
                                                <h5><strong>Specifications</strong></h5>

                                                {
                                                    product.specifications.split('**PENGUINS_BREAK_POINT**').filter(item=>item && item !== "\n").map((item, idx)=>(
                                                        <div className='d-flex' key={idx}>
                                                            <CheckIcon color="primary" style={{marginRight: '5px'}} />
                                                            <span><i>{item.replace('#', '').replace('â', '')}</i></span>
                                                        </div>
                                                    ))
                                                }
                                                
                                            </div>
                                            
                                        :""
                                    }

                                </div>
                            </Grid>
                        </Grid>
                    :
                        <div style={{padding: '0px 50px'}}>
                            Sorry could not find product.
                        </div>
                }
            </div>

      </Box>

        <Snackbar open={openToast} autoHideDuration={6000} onClose={()=>setOpenToast(false)}>
            <Alert onClose={()=>setOpenToast(false)} severity="success">
                You have been redirected to whatsapp messages to complete your order. Please check on redirect link to complete order. Thank you for shopping with us.
            </Alert>
        </Snackbar>
    </div>
  );
}
