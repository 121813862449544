import { Avatar, Button, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './style'
import shopping from '../../images/shopping.png'

export default function AboutMe(props) {
    
    const classes =useStyles ()


    return (
        
        <div className={[classes.container, classes.container1].join(' ')}>
            {/* <Divider  style={{margin: '20px 20px'}}/> */}
            <Typography className={classes.title}> Who we are</Typography>
            <Grid container justify="center" align="center" >
                <Grid item xs={12} md={5} className={classes.content}>
                    <Typography className={classes.contentText}>
                        We are your one-stop shop for all your kitchenware needs. We carry a wide variety of kitchenware products, from pots and pans to utensils and gadgets. We have everything you need to equip your kitchen with the latest and greatest kitchenware.
                    </Typography>
                    <Button onClick={()=>window.open('/about', '_self')} color="primary" variant="contained" style={{marginTop: '20px', color: '#fff', borderRadius: 'px', textTransform: 'none'}}>
                        Read more
                    </Button>
                </Grid>
                <Grid item xs={12} md={5}>
                    <img src={shopping} alt="About us" className={classes.image} />
                </Grid>

            </Grid>
        </div>
    )
}