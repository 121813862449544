import {  makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    container: {
        backgroundColor: '#329bb3',
        padding: '50px 0 30px 0',
        marginBottom: '30px',
    },
    title: {
        fontWeight: 'bold',
        color: '#fff',
        fontSize: 22,
        paddingTop: '15px',
    },
    links: {
        color: '#fff',
        fontSize: 14,
    },
    phone: {
        fontSize: 20,
        fontWeight: 'bold'
    },
    appointmentComponent: {
        backgroundColor: 'inherit',
        padding: '10px 20px',
        [theme.breakpoints.down('md')]: {
            
            padding: '20px 20px',
        }
    },
    appointmentComponentLink: {
        color: "#fff",
        textAlign: 'right'
    },
    appointmentDatesContainer: {
        padding: '17px 0'
    },
    appointmentDates: {
        color: "#fff",
        fontWeight: 'bold',
        fontSize: 18,
        padding: '8px 5px',
        textTransform: 'uppercase',
    },
    appointmentTime: {
        color: "#fff",
        textAlign: 'left',
        fontSize: 17,
        padding: '8px 5px',
        textTransform: 'uppercase',
    },
    footerNav: {
        fontSize: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '18px',
        }
    },
    bottomFooter: {
        width: '90%',
        margin: '5%',
        backgroundColor: '#9fdff8',
        padding: '30px',
        borderRadius: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0',
            width: '100%',
            marginTop: '30px',
            borderRadius: '0',
        }
    },
    bottomFooterLink: {color: '#fff'},
    avatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },


}))