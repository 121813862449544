
import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({
    
    carouselDiv: {
        height: '250px',
        
      [theme.breakpoints.up('md')]: {
        height: '450px',
        paddingBottom: '10px'
      },
    }, 
    carouselImg: {
        backgroundImage: 'linear-gradient(#fff2e6, #fff)',
        // background: '#f6f5f3',
        height: '100%',
        width: '100% !important',
        objectFit: 'cover',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        // height: '100%',
        bottom: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0, .3)',
        
      [theme.breakpoints.up('md')]: {
        bottom: 8,
      },
    },
    legendHeader: {
        fontSize: '60px',
        // color: '#ff4d88',
        zIndex: 10,
        textTransform: 'capitalize',
        [theme.breakpoints.down('md')]: {
            fontSize: '25px'
        }
    }, 
    

}))