import { Grid, List, ListItem,ListItemText, ListItemIcon, Typography, Link, Avatar, Box, IconButton } from "@material-ui/core";
import { Facebook, Instagram, LocationCity, Mail, MailOutline, Phone, Twitter } from "@material-ui/icons";
import React,{ Fragment } from "react";
import { useStyles } from "./style";
import {  appBarItems, AppName, PHONE_NUMBER } from '../../utils/constants'
import logo from '../../images/mecca-logo.jpg'

export default function Footer (props) {

    const classes = useStyles()
    const consultationTime = [
        {
            day: 'NAIROBI',
            time: '100 - 500'
        },
        {
            day: 'THIKA',
            time: '200 - 500'
        },
        {
            day: 'KIAMBU',
            time: '250 - 600'
        },
        {
            day: 'MACHAKOS',
            time: '400 - 700'
        },
        {
            day: 'OTHER AREAS',
            time: '500 - 1500'
        },
    ]
    
    return (
        <div className={classes.container}>




            <Grid container>

                <Grid item xs={12} md={4} style={{padding: '10px 20px 10px 20px', color: "#fff",}}>
                    <div className="w-100 justify-content-center mb-4" >
                        
                        <Typography variant="h6" className={classes.title} style={{textAlign: 'center'}}>
                            Contact Us
                        </Typography>
                        
                        <div className="my-2 d-flex w-100 justify-content-center align-items-center">
                            <img className="rounded-pill" src={logo} style={{width: '120px', height: '120px'}} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center" >
                            <Link 
                                href={"tel:+"+PHONE_NUMBER} 
                                className={classes.phone} 
                                style={{color: "#fff"}}
                                target="_blank"
                            >
                                0735280469
                            </Link>
                            <span className="mx-1" >|</span>
                            <Link 
                                href={"tel:+254715838556"} 
                                target="_blank"
                                className={classes.phone} 
                                style={{color: "#fff"}}
                            >
                                0715838556
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center align-items-center" >
                            {/* <Link href="tel:+254715838556" style={{color: "#fff"}}>0715838556</Link> 
                            <span className="mx-2" >|</span> */}
                            <Link 
                                href='mailto:info@meccastore.co.ke'
                                style={{color: "#fff"}}
                                target="_blank"
                            >
                                info@meccastore.co.ke
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center" >
                        
                            <Link 
                                href='https://goo.gl/maps/fLNWwkjQy4iVhSUf8'
                                style={{color: "#fff"}}
                                target="_blank"
                            >
                                Revlon plaza 4th floor suite 24, Nrb
                            </Link>
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <Box>
                                <IconButton
                                    onClick={()=>window.open('https://www.facebook.com/themeccastores', '_blank')}
                                    style={{padding: 8, background: "#4267B2", margin: 5}}
                                >
                                    <Facebook style={{fontSize: '16px', color: '#fff'}}/>
                                </IconButton >
                                <IconButton 
                                    onClick={()=>window.open('https://www.instagram.com/themecca_store/shop', '_blank')}
                                    style={{padding: 8, background: "#f54e41", margin: 5}}
                                >
                                    <Instagram style={{fontSize: '16px', color: '#fff'}}/>
                                </IconButton>
                                <IconButton 
                                    onClick={()=>window.open('mailto:info@meccastore.co.ke?subject=Help and Support&body=Hello mecca stores, Am contacting to enquire about ...(write your question here) ', '_blank')}
                                    style={{padding: 8, background: "#fcb800", margin: 5}}
                                >
                                    <MailOutline style={{fontSize: '16px', color: '#fff'}}/>
                                </IconButton>
                            </Box>
                        </div>
                    </div>

                </Grid>

                <Grid item xs={12} md={4}> 
                    <div className={classes.appointmentComponent}>
                        <Typography variant="h6" className={classes.title}>
                            Delivery cost (kshs)
                        </Typography>
                        <Grid container className={classes.appointmentDatesContainer}>


                            {
                                consultationTime.map((item, index)=>(

                                    <Fragment key={index}>
                                        <Grid item xs={8}>
                                            <Typography className={classes.appointmentDates}>{item.day}</Typography>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Typography className={classes.appointmentTime}>{item.time}</Typography>
                                        </Grid>

                                    </Fragment>
                                ))
                            }
                        </Grid>
                         {/* <Typography className={classes.appointmentComponentLink}>
                            <Link href={"https://api.whatsapp.com/send?phone=" + PHONE_NUMBER} className={classes.links}>Book Appointment</Link>
                        </Typography>  */}
                    </div>
                </Grid>

                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={3} style={{padding: '10px 20px'}} >
                    <Typography variant="h6" className={classes.title}>Useful Links</Typography>
                    <div className="mt-3">

                        {
                            appBarItems.map((item, index)=>(
                                
                                <Typography  key={index} className={classes.footerNav}><Link href={item.url} className={classes.links}>{item.name}</Link></Typography>

                            ))
                        }
                    </div>
                </Grid>


                <Grid item xs={12} className="mt-0 px-2">

                    <hr style={{margin: '10px 0 20px 0', background: '#fff'}}/>

                    <Grid container className="">
                        <Grid item md={1} xs={12} ></Grid>
                        <Grid item xs={12} md={5} className="copyright" style={{marginBottom: '20px', color: '#fff', textAlign: 'center'}}>
                             <span>All Rights Reserved. copyright &copy; 2023 The Mecca Store</span>
                             <span className="d-block">Web developed by <a style={{color: '#fff'}} href="tel:+254746684906">Penguins technologies</a> </span>
                        </Grid>
                        <Grid item xs={12} md={5} className="d-flex" >
                            <div className="d-none d-md-block" style={{flex: 1}}></div>
                            <a href="/" className="text-light text-center footer_link mx-3">Home</a>
                            <a 
                                href='mailto:info@meccastore.co.ke?subject=Help and Support&body=Hello mecca stores, Am contacting to enquire about your terms and conditions.  '
                                className="text-light text-center footer_link  mx-3">Terms & Conditions</a>
                            <a 
                                href='mailto:info@meccastore.co.ke?subject=Help and Support&body=Hello mecca stores, Am contacting to enquire about ...(write your question here) '
                                className="text-light text-center footer_link  mx-3"
                            >
                                Help & support
                            </a>
                        </Grid>
                        <Grid item md={1} xs={12} ></Grid>
                    </Grid>
                    {/* <div className={classes.bottomFooter}>
                        <Grid container >
                            <Grid item xs={12} md={6}>
                                <Typography>Copyright 2022 Penguins technologies</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                
                                <Avatar src={logo} className={classes.avatar} />
                            </Grid>
                            
                            <Grid item xs={12} md={3}>
                            <Typography>Website design by <Link className={classes.bottomFooterLink} href={"https://api.whatsapp.com/send?phone=254746684906"}>Penguins Technologies</Link></Typography>
                            </Grid>
                        </Grid>
                    </div> */}
                </Grid>

            </Grid>

        </div>
    )

}
