import React, { useEffect, useState } from 'react'


import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import carousel1 from '../../images/carousel1.jpg'
import carousel2 from '../../images/carousel2.jpg'
import carousel3 from '../../images/carousel3.jpg'
import { useStyles } from './style';
import Button from '../Button';
import { Button as MUIbtn, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router';
import allProducts from '../../Data/shop_data.xlsx'
import * as xlsx from 'xlsx';

export default function CarouselComponent (props) {
    
    const classes = useStyles()
    const navigate = useNavigate()
    const [services, setServices] = useState([])

    const handleShopAll = ()=>{
        window.open('/shop', '_self')
    }

    const carouselItems = [
        {
            img: carousel1,
            legend: "Elegant and quality products",
            action: <Button text={"Shop Now"} />
        },
        {
            img: carousel2,
            legend: "5% off on kitchen ware and cutlery",
            action: <Button text={"Shop Now"} />
        },
        {
            img: carousel3,
            legend: "My Kitchen, My Style",
            action: <Button color="primary" text={"Shop Now"} />
        },
    ]


    
    useEffect(() => {

        if (allProducts) {
            var request = new XMLHttpRequest();
            request.open('GET', allProducts, true);
            // request.responseType = 'blob';
            request.responseType = "arraybuffer";
            request.onload = function() {


                
                /* convert data to binary string */
                var data = new Uint8Array(request.response);
                var arr = new Array();
                for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
                var excelFile = arr.join("");

                const workbook = xlsx.read(excelFile, { type: "binary" })
                const sheetName = workbook.SheetNames[0]
                const worksheet = workbook.Sheets[sheetName]
                const json = xlsx.utils.sheet_to_json(worksheet)
                console.log(json)
                setServices([json[243], json[140], json[270]])
            };
            request.send();
        }
    
      return () => null
    }, [])

    return (
        <Carousel
            interval={2000}
            autoPlay={true}
            infiniteLoop={true}
            showArrows={true}
            showIndicators={true}
            showThumbs={false}
            showStatus={false}
        >

            {
                carouselItems.length > 0?

                carouselItems.map((product,index)=>( 
                        <div key={index} className={classes.carouselDiv}>
                            <div className={classes.overlay}></div>
                            <img src={product.img} alt="carousel" className={classes.carouselImg} />
                            <div className="legend text-center" style={{opacity: '1', backgroundColor: 'inherit', color: '#fff', width: '40%', marginLeft: 0, left: index !== 1?'60%': '5%', display: 'd-flex', alignItems: 'center', bottom: '30%'}}>

                                <Typography variant="h1" color="blue" className={classes.legendHeader}>{product.legend.toLowerCase()}</Typography>
                                {/* <MUIbtn onClick={handleShopAll} color="secondary" variant="contained" size="large" style={{borderRadius: '20px', textTransform: 'none', color: '#fff'}}>
                                    Shop now
                                </MUIbtn> */}
                            </div>
                        </div>
                    ))

                :""
            }
        </Carousel>
    )
}
