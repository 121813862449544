import MainTheme from '../Themes/mainTheme'
import Home from '../views/Home'
import About from '../views/About'
import Contact from '../views/Contact'
// import Gallery from '../views/Gallery'
import Shop from '../views/Shop'
import ProductPage from '../views/Product'

export const routes = [
    {
      path: '/',
      element: <MainTheme />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/about', element: <About /> },
        { path: '/contact', element: <Contact /> },
        // { path: '/gallery', element: <Gallery /> },
        { path: '/shop', element: <Shop /> },
        { path: '/shop/:category', element: <Shop /> },
        { path: '/products/:prodName', element: <ProductPage /> },
      ]
    }
  ]